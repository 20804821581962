<template>
  <b-button-group>
    <div
      v-if="modulo == 'clientes' || modulo == 'proveedores'"
    >
      <b-button
        v-if="$can('create', modulo)"
        variant="secondary"
        class="btn-sm btn-icon mr-1 rounded"
        title="Duplicar"
        @click="$emit('processGoToClone', data.item)"
      >
        <feather-icon
          icon="CopyIcon"
        />
      </b-button>
    </div>
    <b-button
      variant="secondary"
      class="btn-sm btn-icon mr-1 rounded"
      :title="$can('update', modulo)
        ? 'Editar'
        : `No tienes permisos para editar ${moduloTitle}`"
      @click="$can('update', modulo)
        ? $emit('processGoToUpdate', data.item)
        : ''"
      :disabled="!$can('update', modulo)"
    >
      <feather-icon
        icon="Edit2Icon"
      />
    </b-button>
    <b-button
      variant="secondary"
      class="btn-sm btn-icon rounded"
      @click="$can('delete', modulo)
        ? !estado
          ? ''
          : $emit('processRemove', data.item.id)
        : ''"
      :title="$can('delete', modulo)
        ? !estado
          ? moduloCreado + ': ' + cantidad
          : 'Eliminar'
        : `No tienes permisos para eliminar ${moduloTitle}`"
      :disabled="!estado || !$can('delete', modulo)"
    >
      <feather-icon
        icon="TrashIcon"
      />
    </b-button>
  </b-button-group>
</template>

<script>
import {
  BButton, BButtonGroup,
} from 'bootstrap-vue'
import { formatos } from '@/mixins/forms'

export default {
  components: {
    BButton,
    BButtonGroup,
  },
  mixins: [formatos],
  computed: {
    moduloTitle() {
      return this.modulo === 'categorias'
        ? 'Categorías' : this.primeraMayuscula(this.modulo)
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    modulo: {
      type: String,
      required: true,
    },
    estado: {
      type: Boolean,
      default: true,
    },
    cantidad: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      moduloCreado: '',
    }
  },
  mounted() {
    switch (this.modulo) {
      case 'categorias':
        this.moduloCreado = this.cantidad <= 1
          ? 'Producto' : 'Productos'
        break

      case 'ubicaciones':
        this.moduloCreado = this.cantidad <= 1
          ? 'Producto' : 'Productos'
        break

      case 'clientes':
        this.moduloCreado = this.cantidad <= 1
          ? 'Salida' : 'Salidas'
        break

      case 'proveedores':
        this.moduloCreado = this.cantidad <= 1
          ? 'OC' : 'OCs'
        break

      default:
        break
    }
  },
}
</script>
